/* ------ 1. Colors -------------------*/

/* ------------- 1.1 positive colors ------*/

.ui.positive.button,
.ui.positive.buttons .button {
  background-color: var(--success-color);
}

i.green.icon,
i.positive.icon {
  color: var(--success-color) !important;
}

/* ------------- 1.2 warning colors ------*/

i.yellow.icon,
i.warning.icon {
  color: var(--warning-color) !important;
}

/* ------------- 1.3 negative colors ------*/

.ui.negative.button,
.ui.negative.buttons .button {
  background-color: var(--error-color);
}

i.red.icon,
i.negative.icon {
  color: var(--error-color) !important;
}

/* ------------- 1.4 Primary color ---------- */

.ui.button.primary {
  background-color: var(--theme-main-color);
}

.ui.button.primary:hover,
.ui.button.primary:active {
  background-color: var(--theme-hover-color);
}

/* ------------ 2.1 UI Modals ---------------- */

.ui.modal > div.header {
  background-color: var(--theme-light-color-1);
  color: white;
}

/* ------------- 2.2 UI Headers --------------- */

.ui.attached.header {
  background-color: var(--theme-light-color-1);
  color: white;
}
