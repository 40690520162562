.services.container {
  display: flex;
}

.ui.dropdown.serviceLevelDropdown {
  display: inline-block;
  margin-left: 2em;
}

.services.container .informationSegment {
  flex: 1 1 auto;
  padding-right: 2em;
}

.services.container .informationSegment ul {
  padding-inline-start: 1.2em;
}
