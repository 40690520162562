.ui.menu.desktopMenu {
  border-radius: 0;
  min-height: 3.5em;
  background-color: var(--theme-alternative-font-color);
}

.ui.menu.desktopMenu a.active {
  background-color: var(--theme-main-color);
  color: white;
}

/* Do not show dropdown menus just because the NavLink in the menu bar is active */
.ui.menu.desktopMenu .ui.active.dropdown:not(:hover) > .menu {
  top: inherit !important;
  height: 0;
  width: 0;
  overflow: hidden;
  opacity: 0;
}

.ui.menu.desktopMenu .dropdown.myProperties .item {
  padding-right: 5em !important;
}

.ui.menu.desktopMenu .dropdown.myProperties .item > .image {
  margin-top: unset;
  margin-bottom: unset;
  vertical-align: middle;
}
