:root {
  --theme-main-color: #016fd0;
  --theme-light-color-1: #82b6e8;
  --theme-alternative-font-color: rgb(235, 239, 248);
  --theme-hover-color: #016fd0;

  --success-color: #5aa856;
  --warning-color: #ffc107;
  --error-color: #993333;
}

a {
  color: unset;
}

/* ----------- React Table ------------------- */

.ReactTable .rt-thead .rt-th {
  background-color: var(--theme-light-color-1);
  color: white;
  border-right-color: white;
}

.ReactTable .ui.toggle.checkbox input:checked ~ label:before {
  background-color: var(--theme-main-color) !important;
}

.ReactTable .ui.label {
  background-color: var(--theme-light-color-1);
  color: white;
}
