.floorplans.container {
  display: grid;
}

.floorplans.container .image-gallery {
  max-width: 800px;
}

.floorplansContainer {
  height: 70vh;
}
.floorplansContainer .image-gallery-slide-wrapper {
  height: calc(100% - 80px);
}
.floorplansContainer .image-gallery,
.floorplansContainer .image-gallery-content,
.floorplansContainer .image-gallery-slides,
.floorplansContainer .image-gallery-swipe,
.floorplansContainer .image-gallery-slide,
.floorplansContainer .image-gallery-image,
.floorplansContainer img {
  height: 100%;
}
.floorplansContainer img {
  object-fit: contain;
}
