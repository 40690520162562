.mobileMenu .ui.menu.topMenuBar {
  border-radius: 0;
}

.mobileMenu .sidebar {
  font-size: 1.2em;
}

.mobileMenu .sidebar .item.property img {
  display: inline-block;
  margin-right: 1em;
}
