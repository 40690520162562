/* Menu Bar */
.amex.tabMenuContainer > .tabular.menu {
  gap: 1px;
  flex-wrap: nowrap;
}

.amex.tabMenuContainer > .tabular.menu > .item {
  background-color: var(--theme-light-color-1);
  text-transform: uppercase;
  color: white;
  justify-content: center;
  padding: 1.52857143em 0.42857143em;
  letter-spacing: 1px;
  flex: 1 1 100%;
}

.amex.tabMenuContainer > .tabular.menu > .item:last-child {
  border-top-right-radius: 0.28571429rem;
}

.amex.tabMenuContainer > .ui.tabular.menu > .active.item {
  margin: unset;
  background-color: var(--theme-main-color);
}
.amex.tabMenuContainer > .ui.tabular.menu > .active.item:not(:first-child) {
  border-radius: 0 !important;
}

/* Segments */
.amex.tabMenuContainer div.active.segment {
  min-height: 300px;
}
