.generalInformation.container {
  display: flex;
  flex-wrap: wrap;
}

.generalInformation.container > * {
  flex: 0.3 0 auto;
}

.generalInformation.container .customList {
  padding-inline-start: 1.2em;
}

.generalInformation.container .equipmentContainer {
  display: flex;
}
.generalInformation.container .equipmentContainer > ul:first-child {
  margin-right: 2em;
}
