.image-gallery {
  margin-bottom: 75px;
  width: 100%;
}

.image-gallery .image-gallery-content,
.image-gallery .image-gallery-slide-wrapper,
.image-gallery .image-gallery-slides,
.image-gallery .image-gallery-swipe,
.image-gallery .image-gallery-slide,
.image-gallery .image-gallery-image {
  height: 100%;
}

.image-gallery img {
  height: 100%;
  object-fit: cover;
}
