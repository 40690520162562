.propertyGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1em;
}

/* Override Semantic UI card behavior */
.propertyGrid > .propertyCard:first-child {
  margin-top: 1em;
}
.propertyGrid > .propertyCard:last-child {
  margin-bottom: 1em;
}
