#applePwaHintIPhone {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
}
#applePwaHintIPad {
  position: absolute;
  right: 0;
  top: 0;
}
#applePwaHintIPad::before {
  right: 30%;
  left: unset;
}

#applePwaHintIPhone img,
#applePwaHintIPad img {
  height: 1.5em !important;
  vertical-align: text-bottom;
}
